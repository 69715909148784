<template>
<v-card v-if="audit">
    <v-card-title>{{ audit.naam }}</v-card-title>
    <v-tabs  color="deep-purple accent-4"
      right>
        <v-tab :to="{ name: 'AuditDashboard' }">Dashboard</v-tab>
        <v-tab :to="{ name: 'Gekoppeld' }">Deelnemers</v-tab>
    </v-tabs>
    <router-view></router-view>
</v-card>
<v-card v-else>
    <v-card-title>error</v-card-title>
    <v-card-text>Vragenlijst bestaat niet</v-card-text>
</v-card>
</template>

<script>

import client from "api-client";

export default {
    name: "SelectedAudit",
    data: () => ({
        audit: null
    }),
    watch: {
        "$route.params.auditId": {
            handler: function (auditId) { 
                this.getAudit(auditId)
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getAudit(auditId) {
         
            client.fetchAudit(this.$route.params.instantieId,auditId).then((data) => {
                this.audit = data;
            });
        }
    }
};
</script>